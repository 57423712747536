<template lang="html">

  <section class="select-years">
    <div class="container-select">
      <label for="historico">Histórico </label>
      <select name="historico" id="historico" v-if="arrayYears" v-model="yearSelected" @change="goYear()">
        <option v-for="(year, key) in Object.values(arrayYears)" :key="key" :value="year">{{year}}</option>
      </select>
    </div>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'select-years',
    props: ['dataYear'],
    mounted () {

    },
    data () {
      return {
        yearSelected: this.dataYear
      }
    },
    methods: {
      range: function range(start, stop = undefined, step = 1) {
        const startArray = stop === undefined ? 0 : start;
        const stopArray = stop === undefined ? start : stop;
        return Array.from({ length: (stopArray - startArray) / step + 1 }, (_, i) => startArray + (i * step)).reverse();
      },
      goYear(year) {
        this.$emit('changed', this.yearSelected);
      },
    },
    computed: {
      arrayYears() {
        return this.range(2003, new Date().getFullYear())
      }
    }
}


</script>

<style scoped lang="scss">
  .select-years {

  }
</style>
