<template lang="html">

  <section class="hemeroteca-medias">
    <hero-global img="/img/hero/actualidad.svg" title="Actualidad" subtitle="Actualidad" />
    <section id="multimedia" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Galería de imágenes">Galería</h2>
      </div>
      <div class="d-flex jcfe aie m-bottom-20-px">
        <select-years @changed="setYear" :dataYear="yearSelected"></select-years>
      </div>
      <template v-if="loading">
        <div class="result-box container">
          <p class="subtitle">Cargando...</p>
        </div>
      </template>
      <template v-else>
        <section class="grid-3" v-if="store.getImages(this.yearSelected, this.page)">
          <template v-for="(img, index) in Object.values(store.getImages(this.yearSelected, this.page))" :key="index">
            <newVerticalImg :dataContent="img" typeContent="media"></newVerticalImg>
          </template>
        </section>
        <template v-else>
          <div class="result-box container">
            <p class="subtitle">Lo sentimos no hay resultados</p>
          </div>
        </template>
      </template>
      <paginator class="m-bottom-5-rem" v-if="store.getImages(this.yearSelected, this.page)" :pageActual="page" :totalItems="MediaMaxItems" @clicked="changePages" @changed="changePages" />
    </section>
    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import Paginator from "@/components/Global/PaginationGlobal";
  import SelectYears from "@/components/Global/SelectYears";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import newVerticalImg from "@/components/Card/NewVerticalImg";
  import { contentStore } from '@/stores/contents'
  const store = contentStore();
  export default {
    name: 'hemeroteca',
      props: ['Nyear'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      newVerticalImg,
      Paginator,
      SelectYears
    },
    setup() {
      return {
        store,

      }
    },
    mounted() {
      this.store.loadImages(
        {
          page: this.page,
          maxYear: this.yearSelected,
        }
      )
    },
    data() {
      return {
        year: new Date().getFullYear(),
      }

    },
    methods: {
      changePages(item, page) {
        console.log(item, page)
        this.store.setPagesImages(item)
        this.store.loadImages(
          {
            maxYear: this.yearSelected,
            page: this.page,
          }
        )

        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: 0,
          });
        }, 200);
      },
      setYear(year) {
        this.$router.push({
          name: 'multimediaImagenes',
          params: {
            Nyear: year,
          }
        });
        this.year = year
        this.page = 1
        this.store.images.lastFilters.date = year
        this.store.setPagesImages(1)
        this.store.loadImages(
          {
            maxYear: this.year,
            page: 1,
          }
        )

      }


    },
    computed: {
      MediaMaxItems() {
        return this.store.getPagesImages(this.yearSelected);
      },
      page() {
        return this.store.images.viewPage
      },
      loading() {
        return this.store.loading
      },
      yearSelected() {
        let year = this.Nyear
        return year
      }
    },
    watch: {
      "loading"() {
        if (this.loading) {
          let loader = this.$loading.show({
            container: this.$refs.loadingContainer,
            zIndex: 99,
          });
          setTimeout(() => {
            loader.hide()
          }, 1000)
        }
      }
    }
  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .hemeroteca-medias {
    .title-grand {
      margin-bottom: 25px;
      color: #8baddc5e;
    }

    .result-box {
      min-height: 38vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .subtitle {
        font-size: 30px;
        border-bottom: 1px solid;
      }
    }
  }
</style>